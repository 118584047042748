import {Model, attr, fk, many} from 'redux-orm';
import * as ActionTypes from '../constants/actiontypes';

class Tag extends Model {
    static get modelName() {
        return 'Tag';
    }

    static get fields() {
        return {
            id: attr(),
            label: attr()
        }
    }

    static reducer(action, Tag) {
        const { type, payload } = action;
        switch(type) {
            case ActionTypes.TAG_RECEIVED:
                Tag.upsert({
                    id: payload.key,
                    label: payload.label
                })
                break;

            default:
                return undefined;
        }
    }
}

export default Tag;