import {Model, attr} from 'redux-orm';
import * as ActionTypes from '../constants/actiontypes';

class Activity extends Model {
    static get modelName() {
        return 'Activity'
    }

    static get fields() {
        return {
            id: attr(),
            name: attr(),
            colour: attr()
        }
    }

    static reducer(action, Activity) {
        const { type, payload } = action;
        switch(type) {
            case ActionTypes.ACTIVITY_RECEIVED:
                Activity.upsert({
                    id: payload.id,
                    name: payload.name,
                    colour: payload.color
                });
                break;

            default:
                return undefined;
        }
    }
}

export default Activity;