class Api {
    token = undefined;
    key = 'NDY3MDFfNjYyYTZhYzIyMjE3NDNjNGFhMjc2MmQ1MjgzNjgxNWU=';
    shh = 'ZjIxMDc1YmFkNjU2NDY0YWJhY2ZjODYyNzAyOGEyZWI=';
    
    signInUrl = 'https://api.timeular.com/api/v2/developer/sign-in';
    activitiesUrl = 'https://api.timeular.com/api/v2/activities';
    timeEntriesBaseUrl = 'https://api.timeular.com/api/v2/time-entries/';
    tagsAndMentionsUrl = 'https://api.timeular.com/api/v2/tags-and-mentions';

    auth() {
        var body = JSON.stringify({apiKey: this.key, apiSecret: this.shh});
        return fetch(this.signInUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body
        })
        .then(response => response.json())
        .then(data => {
            this.token = data.token;
        });
    }

    getActivities() {
        return this.auth()
                .then(() => {
                    return fetch(this.activitiesUrl, {
                        method: 'get',
                        headers: {
                            'Authorization' : `Bearer ${this.token}`
                        }
                    })
                });
    }

    getTagsAndMentions() {
        return this.auth()
                .then(() => {
                    return fetch(this.tagsAndMentionsUrl, {
                        method: 'get',
                        headers: {
                            'Authorization' : `Bearer ${this.token}`
                        }
                    });
                });
        
    }

    getTimeEntries = (start, end) => {
        return this.auth()
                .then(() => {
                    var url = `${this.timeEntriesBaseUrl}/${start}T00:00:00.000/${end}T23:59:59.999`;
                    return fetch(url, {
                        method: 'get',
                        headers: {
                            'Authorization' : `Bearer ${this.token}`
                        }   
                    })
                })
    }



}
export default Api;