import React, { Component } from 'react';
import { Box } from 'grommet';
import {Routes} from '../../routes/routes';
import { Switch, Route } from 'react-router-dom';

class Config extends Component {
    constructor(props) {
        super(props);
        this.state = {   }
    }
    render() { 
        return ( 
            <Box 
                align="center"
                height="100%"
                justify="start"
                pad="medium"
                basis="1/3"
                background="#f2f2f2"
                width="100%"
                flex={false}
            >
                <Switch>
                    {
                        Routes.map(r => {
                            return <Route key={Routes.indexOf(r)} path={r.path} component={r.config} exact={r.exact}></Route>
                        })
                    }
                </Switch>
            </Box>
        );
    }
}
 
export default Config;