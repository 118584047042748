import React, { Component } from 'react';


import {Box} from 'grommet';
import { Switch, Route } from 'react-router-dom';

import {Routes} from './routes/routes';

class App extends Component {
  render() {
    return (
        <Box 
            direction="column"
            pad="none"
            align="center"
            justify="center"
            height="100%"
            >
            <Switch>
                {
                    Routes.map((r) => {
                        return <Route key={Routes.indexOf(r)} path={r.path} exact={r.exact} component={r.main} />
                    })
                }
            </Switch>
        </Box>
    );
  }
}

export default App;
