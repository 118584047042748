import React, { Component } from 'react';
import { Box, Button, Clock } from 'grommet';
import { Link } from 'react-router-dom';

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.handleOnClick = this.handleOnClick.bind(this);

    }

    handleOnClick() {
        this.props.history.push('/build');
    }

    render() {
        return (
            <Box align="center">
                <Clock
                    type="digital"
                    pad="large"
                    run="backward"
                    size="xlarge"
                />
                <Link to="/build">
                    <Button as="span" label="Let's bend"></Button>
                </Link>
            </Box>
        );
    }
}

export default Welcome;