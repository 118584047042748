import React, { Component } from 'react';
import { Box, Calendar } from 'grommet';

class DateConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Box
                pad="none"
                width="100%"
            >
                <h2>Dates</h2>
                <h3>Start date</h3>

                <Box 
                    align="center"
                    pad="medium"
                >
                    <Calendar
                        firstDayOfWeek={1}
                        size="small"
                        date={(new Date()).toISOString()}
                        onSelect={(date) => { }}
                    />
                </Box>
                <h3>End date</h3>
                <Box 
                    align="center"
                    pad="medium"
                >
                    <Calendar
                        firstDayOfWeek={1}
                        size="small"
                        date={(new Date()).toISOString()}
                        onSelect={(date) => { }}
                    />
                </Box>
            </Box>
        );
    }
}

export default DateConfig;