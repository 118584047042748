export const theme = {
    global: {
        font: {
            family: 'Roboto',
            size: '20px'
        },
        colors: {
            brand: '#55c58d'
        }
    },
    button: {
        border: {
            radius: '4px'
        }
    }
}