import React, { Component } from 'react';
import { Box } from 'grommet';
import Config from '../config/config';
import Timesheet from '../timesheet/timesheet';


class Builder extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <Box 
                direction="row"
                basis="full"
                height="100%"
                width="100%"
                pad="none"
            >
                <Config />
                <Timesheet />
            </Box>
        );
    }
}
 
export default Builder;