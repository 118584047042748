import React, { Component } from 'react';
import { Box } from 'grommet';

class Timesheet extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <Box 
                align="center"
                height="100%"
                justify="start"
                basis="2/3"
                pad="large"
            >
                <h1>Timesheet</h1>
            </Box>
         );
    }
}
 
export default Timesheet;