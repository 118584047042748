import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import { Grommet } from 'grommet';
import {BrowserRouter} from 'react-router-dom';

import store from './store/store';
import {getData} from './actions/data';
import {theme} from './theme/theme';

store.dispatch(getData());

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Grommet full={true} theme={theme}>
                <App />
            </Grommet>
        </BrowserRouter>
    </Provider>
    
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
