import * as ActionTypes from '../constants/actiontypes';
import Api from '../api/api';

export const getData = () => {
    return (dispatch) => {
        const api = new Api();
        
        api.getActivities()
            .then(response => response.json())
            .then(data => {
                data.activities.forEach(a => {
                    dispatch(createActivity(a));
                })
            });

        api.getTagsAndMentions()
            .then(response => response.json())
            .then(data => {
                data.tags.forEach(t => {
                    dispatch(createTag(t));
                });

                data.mentions.forEach(m => {
                    dispatch(createMention(m));
                });
            })
    }
}

const createActivity = (activity) => {
    return {
        type: ActionTypes.ACTIVITY_RECEIVED,
        payload: activity
    }
}

const createMention = (mention) => {
    return {
        type: ActionTypes.MENTION_RECEIVED,
        payload: mention
    }
}

const createTag = (tag) => {
    return {
        type: ActionTypes.TAG_RECEIVED,
        payload: tag
    }
}