import React from 'react';

import Builder from '../components/builder/builder';
import Welcome from '../components/welcome/welcome';
import DateConfig from '../components/dateConfig/date-config';
import ProjectConfig from '../components/projectConfig/projectConfig';

export const Routes = [
    {
        path: "/",
        exact: true,
        main: () => <Welcome />
    },
    {
        path: "/build",
        exact: true,
        main: () => <Builder />,
        config: () => <DateConfig />
    },
    {
        path: "/build/projects/",
        exact: false,
        main: () => <Builder />,
        config: () => <ProjectConfig />
    }
];